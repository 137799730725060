import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  & h1,
  h2,
  h3,
  h4,
  h5 {
    padding-bottom: 20px;
    font-weight: ${(props) => props.theme.font.weight.m};
  }
`

const StyledImage = styled(Plaatjie)`
  border-radius: 15px;

  & img {
    object-fit: contain !important;
  }

  @media (max-width: 767px) {
    position: absolute;
    opacity: 0.099;
    bottom: 0;
    right: calc(50% - 200px);
    max-width: 400px;
    z-index: -1;
  }
`

interface TextWithImageAndVideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_TextWithImageAndVideo
}

const TextWithImageAndVideo: React.FC<TextWithImageAndVideoProps> = ({ fields }) => {
  const order: string =
    fields?.view?.split('/')[2] === 'L' ? 'order-md-1' : 'order-md-3'

  return (
    <div className="py-3">
      <div className="container py-lg-4">
        <div className="row pt-5 pb-lg-5">
          <div
            className={`d-flex align-items-center col-md-${
              fields?.view?.split('/')[0]
            } pr-lg-5 order-md-2`}
          >
            <Content content={fields.description} />
          </div>
          <div
            className={`col-md-${
              fields?.view?.split('/')[1]
            } mt-5 mt-lg-0 d-lg-flex flex-wrap justify-content-center justify-content-lg-start align-items-center ${order}
            `}
          >
            <iframe 
							src={fields.videoUrl} 
							width="100%" 
							height="197" 
							title="Video"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
							allowFullScreen
            />
            <StyledImage
              loading="eager"
              image={fields?.image}
              alt=""
              className="w-100 mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextWithImageAndVideo
